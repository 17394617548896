var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"c-structured-data"},[_vm._t("default"),_vm._v(" "),_c('tbody',_vm._l((_vm.computedCells),function(tr,rIndex){return _c('tr',{key:`row-r${rIndex}`,class:[
				'c-structured-data__row',

				{
					'c-structured-data__row--sticky':
						_vm.firstRowSticky &&
						_vm.useFirstRowAsHeader &&
						rIndex === 0,
				},
			]},_vm._l((tr),function(tc,cIndex){return _c(tc.scope ? 'th' : 'td',_vm._b({key:`cell-r${rIndex}-c${cIndex}`,tag:"Component",class:[
					'c-structured-data__cell',

					{
						'c-structured-data__cell--sticky':
							_vm.firstColumnSticky &&
							_vm.useFirstColumnAsHeader &&
							cIndex === 0,
					},
				]},'Component',{ scope: tc.scope },false),[_c('div',{domProps:{"innerHTML":_vm._s(tc.value)}})])}),1)}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }