<!--
	Last modified: 2022/05/09 18:26:32
-->
<template>
	<table class="c-structured-data">
		<slot></slot>

		<tbody>
			<tr
				v-for="(tr, rIndex) in computedCells"
				:key="`row-r${rIndex}`"
				:class="[
					'c-structured-data__row',

					{
						'c-structured-data__row--sticky':
							firstRowSticky &&
							useFirstRowAsHeader &&
							rIndex === 0,
					},
				]"
			>
				<Component
					:is="tc.scope ? 'th' : 'td'"
					v-for="(tc, cIndex) in tr"
					:key="`cell-r${rIndex}-c${cIndex}`"
					:class="[
						'c-structured-data__cell',

						{
							'c-structured-data__cell--sticky':
								firstColumnSticky &&
								useFirstColumnAsHeader &&
								cIndex === 0,
						},
					]"
					v-bind="{ scope: tc.scope }"
				>
					<div v-html="tc.value"></div>
				</Component>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'StructuredData',

	props: {
		cells: {
			type: Array,
			required: true,
		},
		useFirstRowAsHeader: {
			type: Boolean,
			default: false,
		},
		useFirstColumnAsHeader: {
			type: Boolean,
			default: false,
		},
		firstRowSticky: {
			type: Boolean,
			default: false,
		},
		firstColumnSticky: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		computedCells() {
			return this.cells.map((row, rIndex) => {
				if (Array.isArray(row)) {
					return row.map((cell, cIndex) => {
						const rowScoped =
							cIndex === 0 && this.useFirstColumnAsHeader;

						const colScoped =
							rIndex === 0 && this.useFirstRowAsHeader;

						return {
							...cell,
							scope:
								cell.scope ||
								(colScoped ? 'col' : rowScoped && 'row'),
						};
					});
				}

				return [];
			});
		},
	},
};
</script>

<style lang="postcss">
:where(.c-structured-data) {
	@apply block text-left;
}

:where(.c-structured-data tbody) {
	@apply block overflow-auto;
}

:where(.c-structured-data__row) {
	@apply w-full;
}

:where(.c-structured-data__row--sticky) {
	@apply sticky top-0 z-20;
}

:where(.c-structured-data__cell--sticky) {
	@apply sticky left-0 z-10;
}

:where(.c-structured-data__cell > div) {
	@apply block w-max;

	max-width: min(24em, 60vw);
}

:where(.c-structured-data__row:first-child
		> .c-structured-data__cell:last-child) {
	@apply w-full;
}
</style>
