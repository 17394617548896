<template>
	<div
		v-if="title || !isEmpty"
		:id="$vnode.key"
		class="c-block-list-structured-data-custom"
	>
		<LongReadTarget
			v-if="title && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<BaseH2 v-if="title" class="mb-xl" v-text="title" />

		<StructuredData
			class="s-structured-data"
			v-bind="{ ...data, firstRowSticky: true, firstColumnSticky: true }"
		/>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';
import StructuredData from '~/citi-baseline/components/StructuredData';

export default {
	name: 'BlockListStructuredDataCustom',
	components: { LongReadTarget, StructuredData },
	inheritAttrs: false,

	props: {
		title: String,
		includeInToc: Boolean,
		data: Object,
	},

	computed: {
		isEmpty() {
			return (
				this.data?.cells.map((e) => e.filter((e) => !!e.value)).flat()
					.length === 0
			);
		},
	},
};
</script>
